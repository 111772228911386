// gets the local storage for the current uid
export const localStorageGet = (uid) => {
    try {
        const storage = localStorage.getItem(uid) ?? {};
        return JSON.parse(storage);
    } catch ( error ) {
        return {};
    }
}

// add the current object sent in value to the local storage
export const localStorageAdd = (uid, value) => {
    try {
        const storage = localStorageGet(uid);        
        const data = {...storage, [Object.keys(value)]:Object.values(value)[0]};        
        localStorage.setItem(uid, JSON.stringify(data));
    } catch ( error ){
        // ignoring it
    }
}